const usertalent = {
    state: {
        usertalentInfo: {},
    },
    mutations: {
        USERTALENT_INFO (state, info) {
            state.usertalentInfo = info
        },
    },
    getters: {
        usertalentInfo: state => {
            return state.usertalentInfo
        },
    },
    actions: {
        saveUsertalentInfo ({ commit }, data) {
            commit('USERTALENT_INFO', data)
        },
    }
};
export default usertalent