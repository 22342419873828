import axios from "axios";
import store from '../../store';
import { notification } from "ant-design-vue";

let http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

const outCheckTokenUrlList = [
    //不需要校验Token的接口列表
    "/login/login",
    "/login/loginByPhone",
    // "/file/upload",
];

// 请求拦截器
http.interceptors.request.use(
    (config) => {
        config.headers["Content-Type"] = "application/json;charset=UTF-8";
        if (config.type === "updateFile") {
            loadingMessage = "上传中···";
            config.headers["Content-Type"] = "multipart/form-data";
            config.baseURL = process.env.VUE_APP_RESOURCES_API_HOST;
            delete config.type;
        } else if (config.type == 'common') {
            config.baseURL = process.env.VUE_APP_PAGECRAWL_API_URL;
        }
        //没有token的情况是没有登录的 排除部分接口不需要token的接口
        var str = config.url.split("?")[0];
        for (var i = 0; i < outCheckTokenUrlList.length; i++) {
            if (outCheckTokenUrlList[i] == str) {
                return config;
            }
        }
        // 设置token，Content-Type
        let token = store.getters.token;
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 响应拦截器
http.interceptors.response.use(
    (res) => {
        if (res.data.rescode == 200 || res.data.rescode == 201) {
            return res.data
        } else if (res.data.rescode == 206) {
            return res.data
        } else {
            if (res.config.url.split("?")[0] != "/userbasic/queryReport") {
                notification['error']({
                    message: '请求失败！',
                    description: res.data.msg
                });
            }
            notification['error']({
                message: '请求失败！',
                description: res.data.msg
            });
            return Promise.reject(res);
        }
    },
    (error) => {
        console.log("error3", error);
        notification['error']({
            message: '网络请求失败！',
        });
        return Promise.reject(error);
    }
);

function get (url, type) {
    return new Promise((resolve, reject) => {
        http.get(url, { type: type })
            .then(
                (response) => {
                    resolve(response);
                },
                (err) => {
                    reject(err);
                }
            )
            .catch((error) => {
                reject(error);
            });
    });
}

function post (url, data, type) {
    return new Promise((resolve, reject) => {
        http.post(url, data, { type: type })
            .then(
                (response) => {
                    resolve(response);
                },
                (err) => {
                    reject(err);
                }
            )
            .catch((error) => {
                notification['warning']({
                    message: '网络错误',
                });
                reject(error);
            });
    });
}

function updateFile (url, data, loading) {
    return new Promise((resolve, reject) => {
        http.post(url, data, { loading: loading, type: "updateFile" })
            .then(
                (response) => {
                    if (response.data.rescode === 200) {
                        resolve(response);
                    } else {
                        notification['warning']({
                            message: response.data.msg,
                        });
                    }
                },
                (err) => {
                    notification['warning']({
                        message: '网络错误',
                    });
                    reject(err);
                }
            )
            .catch((error) => {
                notification['warning']({
                    message: '网络错误',
                });
                reject(error);
            });
    });
}

export { get, post, updateFile };
