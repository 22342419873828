<template>
	<div style="  cursor: pointer;" @click="initSelectorMap">
		<a-input style=" width: 388px; height: 32px; font-size: 14px; background-color: #fff; color: #595959; cursor: pointer; " disabled v-model="place" />

		<div v-if="showText" class="checkMap">
			<img src="../assets/img/location.png" style="display: block; width: 20px; height: 20px; margin-right: 4px; margin-left: 3px;" />
			<span class="maptext">选择位置</span>
		</div>

		<SelectorMap ref="selectorMap" @change="selectMap"></SelectorMap>
	</div>
</template>

<script>
import SelectorMap from './SelectorMap';
import options from '../utils/common/cascader-address-options';
export default {
	components: {
		SelectorMap,
	},
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
	},
	watch: {
		value: {
			handler(val) {
				this.place = val;
			},
			immediate: true,
		},
	},
	data() {
		return {
			place: '',
			options: options,
			showText: true,
		};
	},
	methods: {
		initSelectorMap() {
			this.$refs.selectorMap.init(this.options);
		},
		selectMap(value) {
			this.place = value.regions.join('/');
			this.$emit('change', value);
			console.log(value);
			if (value) {
				this.showText = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.checkMap {
	position: absolute;
	top: 0em;
	left: 10px;
	display: flex;
	align-items: center;
	height: 38px;
	.maptext {
		display: block;
		color: #2d7bf8;
		font-size: 14px;
	}
}
</style>
