<template>
    <a-config-provider :locale="zh_CN">
        <div id="app">
            <Layout />
        </div>
    </a-config-provider>
</template>
<script>
import Layout from "./components/Layout";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
    data() {
        return { zh_CN };
    },
    created() {
        const flag = this.$CommonUtil.isMobile();
        if (flag) {
            this.$router.replace({ name: "Mobile" });
        }
    },
    components: {
        Layout,
    },
};
</script>
<style lang="less">
body {
    // font-family: "PingFangSC-Medium" !important;
    color: #000000 !important;
}
.flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}
.align-center {
    align-items: center;
}
.ant-divider {
    margin: 0;
}
.pageContainer {
    height: 100%;
    padding: 8px;
    .home {
        .ant-steps-item-process .ant-steps-item-icon {
            background-color: #fff;
        }
        .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
            color: #1890ff;
        }
        .ant-steps-item-wait .ant-steps-item-icon {
            border-color: #1890ff;
        }
        .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
            color: #1890ff;
        }
        .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #1890ff;
        }
        .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: #1890ff;
        }
    }
}
.forgetContainer {
    height: 100%;
    padding: 8px;
    .ant-input {
        height: 50px;
        font-size: 18px;
    }
}
.registerContainer {
    height: 100%;
    padding: 8px;
    .ant-input {
        height: 40px;
    }
}
.ant-layout-sider {
    background-color: #fff;
}
</style>
