const domainInfo = {
    state: {
        domainInfo: {}
    },
    mutations: {
        DOMAININFO (state, info) {
            state.domainInfo = info
        }
    },
    getters: {
        domainInfo: state => {
            return state.domainInfo
        }
    },
    actions: {
        saveDomainInfo ({ commit }, data) {
            commit('DOMAININFO', data)
        }
    }
};
export default domainInfo