<template>
    <a-modal :visible="updataAddressVisible" title="更新地址" @cancel="closeModal" :confirmLoading="spinning" @ok="submit">
        <a-spin :spinning="spinning">
            <a-alert class="alert" message="开通会员前，请更新您的地址信息" banner></a-alert>
            <a-form-model ref="Form" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                <a-form-model-item label="选择区域" prop="region">
                    <a-cascader :options="options" v-model="form.region" placeholder="选择区域" />
                </a-form-model-item>
                <a-form-model-item label="详细地址" prop="address">
                    <a-input v-model="form.address" placeholder="请输入详细地址" :maxLength="20" />
                </a-form-model-item>
            </a-form-model>
        </a-spin>
    </a-modal>
</template>
<script>
import options from "../utils/common/cascader-address-options";
options.map((provinceItem) => {
    provinceItem.value = provinceItem.label;
    provinceItem.children.map((cityItem) => {
        cityItem.value = cityItem.label;
        cityItem.children.map((districtItem) => {
            districtItem.value = districtItem.label;
        });
    });
});
export default {
    props: ["updataAddressVisible", "closeUpdataAddressModal"],
    data() {
        return {
            options: options,
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            spinning: false,
            userInfo: {},
            form: {
                region: [],
                address: "",
            },
            rules: {
                region: [{ required: true, trigger: "blur", message: "请选择区域" }],
                address: [{ required: true, trigger: "change", message: "请输入详细地址" }],
            },
        };
    },
    watch: {
        updataAddressVisible(data) {
            if (data) {
                this.userInfo = this.$store.getters.userInfo;
            }
        },
    },
    methods: {
        submit() {
            const _this = this;
            this.$refs.Form.validate((valid) => {
                if (valid) {
                    console.log(_this.form);
                    let params = {
                        province: _this.form.region[0],
                        city: _this.form.region[1],
                        district: _this.form.region[2],
                        address: _this.form.address,
                        userCode: _this.userInfo.userCode,
                    };
                    _this.$http
                        .post("/userconsumer/update", params)
                        .then((res) => {
                            if (res.rescode == 200) {
                                _this.getUserInfo();
                            } else {
                                _this.$message.console.error("更新地址失败");
                            }
                        })
                        .catch((err) => {
                            _this.spanning = false;
                        });
                }
            });
        },
        getUserInfo() {
            const _this = this;
            _this.$http
                .get("/userconsumer/query?userCode=" + _this.userInfo.userCode)
                .then((res) => {
                    if (res.rescode == 200) {
                        _this.$store.dispatch("saveUserInfo", res.data);
                        _this.$message.success("更新地址成功");
                        _this.closeModal();
                    } else {
                        _this.$message.console.error("更新地址失败");
                    }
                })
                .catch((err) => {
                    _this.spanning = false;
                });
        },
        closeModal() {
            this.closeUpdataAddressModal();
            this.$refs.Form.resetFields();
        },
    },
};
</script>
<style lang="less" scoped>
.alert {
    margin-bottom: 8px;
}
</style>
