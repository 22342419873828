<template>
	<a-modal top="5vh" v-if="dialogVisible" title="选择区域" :visible="dialogVisible" width="70%" append-to-body closable @cancel="close" :footer="false">
		<a-alert style="text-align: left;" :message="zoom > 15 && address ? `已选择：${address}` : '当前位置不精准，请放大地图标记位置'" :type="zoom > 15 ? 'success' : 'error'" show-icon />
		<a-row class="mb-16 mt-16" :gutter="20">
			<a-col :span="6">
				<a-cascader style="width: 100%;" placeholder="选择区域" v-model="regions" :options="options" output="text" @change="district" />
			</a-col>
			<!-- <a-col :span="6">
				<el-input id="tipInput" placeholder="请输入" v-model="search" @input="searchKeyword"></el-input>
				<a-select style="width: 60%;" v-model="search" @change="handelrChange" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading">
					<a-select-option v-for="(item, index) in options" :key="index" :value="item">{{ item.name }}（{{ item.district }}）</a-select-option>
				</a-select>
			</a-col> -->
			<a-col :span="18" class="text-r">
				<a-button type="primary" @click="confirm">已确定位置</a-button>
			</a-col>
		</a-row>
		<div class="amap-wrapper">
			<div id="panel" class="panel"></div>
			<el-amap ref="amap" class="amap-box" :plugin="plugins" :visible="false" :vid="'amap-vue'" :events="events" :center="center">
				<el-amap-marker :position="center" :visible="visible" icon="https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png"></el-amap-marker>
			</el-amap>
		</div>
	</a-modal>
</template>

<script>
export default {
	data() {
		let self = this;
		return {
			dialogVisible: false,
			city: '',
			options: [],
			search: '',
			loading: false,
			center: [112.938882, 28.228304],
			visible: false,
			address: '',
			zoom: 10,
			regions: [],
			events: {
				click(event) {
					self.zoom = self.$refs.amap.$$getInstance().getZoom();
					if (self.$refs.amap.$$getInstance().getZoom() > 15) {
						self.center = [event.lnglat.lng, event.lnglat.lat];
						self.getaddress(self.center);
						self.visible = true;
					} else {
						self.$message.warning('请放大地图在选择位置');
					}
				},
				zoomend(event) {
					self.zoom = self.$refs.amap.$$getInstance().getZoom();
				},
			},
			plugins: [
				{
					enableHighAccuracy: true, //是否使用高精度定位，默认:true
					timeout: 5000, //超过10秒后停止定位，默认：无穷大
					maximumAge: 0, //定位结果缓存0毫秒，默认：0
					convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
					showButton: false, //显示定位按钮，默认：true
					buttonPosition: 'LB', //定位按钮停靠位置，默认：'LB'，左下角
					showMarker: false, //定位成功后在定位到的位置显示点标记，默认：true
					showCircle: false, //定位成功后用圆圈表示定位精度范围，默认：true
					panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
					zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
					extensions: 'all',
					pName: 'Geolocation',
					events: {
						init(o) {
							// o 是高德地图定位插件实例
							o.getCurrentPosition((status, result) => {
								if (result && result.position) {
									self.center = [result.position.lng, result.position.lat];
									self.getaddress(self.center);
									self.$nextTick();
								}
							});
							AMap.event.addListener(o, 'complete', result => {
								self.center = [result.position.lng, result.position.lat];
								self.getaddress(self.center);
								self.$nextTick();
							});
						},
					},
				},
				{
					//左下角缩略图插件 比例尺
					pName: 'Scale',
				},
				{
					//地图工具条
					pName: 'ToolBar',
					// 位置右下角
					position: 'LT',
					// 定位失败后，开启IP定位
					noIpLocate: false,
					// 是否显示定位按钮
					locate: false,
					// 方向键盘是否可见，默认为true
					direction: false,
					//是否自动定位，即地图初始化加载完成后，是否自动定位的用户所在地，仅在支持HTML5的浏览器中有效，默认为false
					autoPosition: true,
					init(o) {
						// console.log(o);
					},
				},
			],
		};
	},
	mounted() {
		// this.mapSearchInit();
	},
	methods: {
		// 初始化
		init(options) {
			options.map(provinceItem => {
				provinceItem.value = provinceItem.label;
				provinceItem.children.map(cityItem => {
					cityItem.value = cityItem.label;
					cityItem.children.map(districtItem => {
						districtItem.value = districtItem.label;
					});
				});
			});
			this.options = options;
			this.dialogVisible = true;
		},
		// 地址解码
		getaddress: function(lnglat) {
			let _this = this;
			AMap.plugin('AMap.Geocoder', function() {
				var geocoder = new AMap.Geocoder({
					radius: 200,
					extensions: 'all',
				});
				geocoder.getAddress(lnglat, function(status, result) {
					if (status === 'complete' && result.info === 'OK') {
						const {
							addressComponent: { province, city, district, township, street, streetNumber },
							pois,
						} = result.regeocode;
						_this.regions = [province, city, district];
						_this.address = pois.length > 0 ? township + street + pois[0].name : township + street + streetNumber;
					}
				});
			});
		},
		// 省市区查询
		district: function(val) {
			const _this = this;

			let search;
			if (val.length === 1) {
				search = val[0];
				this.city = val[0];
			}
			if (val.length === 2) {
				search = val[1];
				this.city = val[1];
			}
			if (val.length === 3) {
				search = val[2];
				this.city = val[2];
			}
			AMap.plugin('AMap.DistrictSearch', function() {
				var DistrictSearch = new AMap.DistrictSearch();
				DistrictSearch.search(search, function(status, result) {
					if (status === 'complete' && result.info === 'OK') {
						const [{ center }] = result.districtList;
						_this.center = [center.lng, center.lat];
						_this.$refs.amap.$$getInstance().setZoom(16);
						_this.visible = true;
					}
				});
			});
		},
		// 输入查询提示项
		remoteMethod(value) {
			if (!value) return;
			const _this = this;
			AMap.plugin(['AMap.Autocomplete'], function() {
				let autoOptions = {
					//city 限定城市，默认全国
					city: _this.city || '全国',
				};
				// 实例化AutoComplete
				let autoComplete = new AMap.Autocomplete(autoOptions);
				// 根据关键字进行搜索
				autoComplete.search(value, function(status, result) {
					// 搜索成功时，result即是对应的匹配数据
					if (status === 'complete' && result.info === 'OK') {
						_this.options = result.tips;
					}
				});
			});
		},
		// 选择查询
		handelrChange(value) {
			const _this = this;
			AMap.plugin(['AMap.PlaceSearch'], function() {
				//构造地点查询类
				let placeSearch = new AMap.PlaceSearch({
					pageSize: 5, // 单页显示结果条数
					pageIndex: 1, // 页码
					city: value.adcode, // 兴趣点城市
					citylimit: true, //是否强制限制在设置的城市内搜索
					map: _this.$refs.amap.$$getInstance(), // 展现结果的地图实例
					panel: 'panel', // 结果列表将在此容器中进行展示。
					autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
				});
				//关键字查询
				placeSearch.search(value.name);
				AMap.event.addListener(placeSearch, 'selectChanged', _this.selectChanged);
			});
		},
		// 右侧列表选择
		selectChanged(val) {
			const {
				selected: {
					data: { location },
				},
			} = val;
			this.center = [location.lng, location.lat];
			this.getaddress(this.center);
		},
		// 确定选择
		confirm() {
			let obj = {
				location: this.center,
				address: this.address,
				regions: this.regions,
			};

			const [province] = this.regions;
			if (province === '香港特别行政区' || province === '澳门特别行政区') {
				this.$message.error('当前区域暂未开通服务');
				return;
			}
			if (this.zoom > 15 && this.address) {
				this.$emit('change', obj);
				this.close();
			} else {
				this.$message.warning('请放大地图在选择位置');
			}
		},
		// 关闭
		close() {
			this.options = [];
			this.city = '';
			this.search = '';
			this.address = '';
			this.regions = [];
			this.visible = false;
			this.center = [112.938882, 28.228304];
			this.zoom = 10;
			this.dialogVisible = false;
		},
	},
};
</script>

<style scoped lang="less">
.amap-wrapper {
	height: 70vh;
	width: 100%;
	position: relative;
}
/deep/.el-alert__title {
	color: #333333 !important;
	font-size: 16px;
}
/deep/.el-input__icon {
	display: none;
}
.mt-16 {
	margin: 20px 0;
}
.panel {
	position: absolute;
	background-color: white;
	max-height: 90%;
	overflow-y: auto;
	top: 10px;
	right: 10px;
	z-index: 999;
	width: 280px;
}
.text-r {
	text-align: right;
}
</style>
