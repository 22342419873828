<template>
    <a-modal :visible="openVipModalvisibile" :title="title" @cancel="closeModal" :footer="false">
        <div v-if="vipPriceInfo.amount">
            <a-row class="price">
                <a-col :span="4">会员价格：</a-col>
                <a-col>{{ vipPriceInfo.amount }} / 年 </a-col>
            </a-row>
            <a-row class="price" v-if="userInfo.isVip == 1">
                <a-col :span="4">会员到期：</a-col>
                <a-col :span="20">{{ getDate(userInfo.freeReviewTime) }} <a-tag color="#f5222d" v-if="vipOutTime">已过期</a-tag></a-col>
            </a-row>
            <a-row class="flex">
                <a-col :span="4">会员权益：</a-col>
                <a-col>
                    <a-row>广告智能检测免费使用1年!</a-row>
                    <a-row>广告人工检测免费使用1年!</a-row>
                    <a-row>合同内容审查免费使用1年!</a-row>
                </a-col>
            </a-row>
            <a-row class="submit">
                <a-col :span="24" class="flex justify-center">
                    <a-button type="primary" @click="openVip">立即开通</a-button>
                </a-col>
            </a-row>
        </div>
        <div v-else>
            <a-alert message="您的账号注册所在地未设置会员价格收费标准，请联系客服：15367448263" type="info"></a-alert>
        </div>
        <PayModal :payData="payData" :payModalVisible="payModalVisible" :tradeId="tradeId" :closePayModal="closePayModal" :closeOpenVipModal="closeOpenVipModal" />
    </a-modal>
</template>
<script>
import PayModal from "./PayVipModal.vue";
export default {
    components: {
        PayModal,
    },
    props: ["openVipModalvisibile", "closeOpenVipModal"],
    data() {
        return {
            userInfo: {},
            title: "",
            domainInfo: {},
            vipPriceInfo: {},
            payData: {},
            payModalVisible: false,
            tradeId: "",
            currentTimeStamp: "",
            vipOutTime: false,
            vipInfo: {},
        };
    },
    watch: {
        openVipModalvisibile(data) {
            if (data) {
                this.userInfo = this.$store.getters.userInfo;
                this.currentTimeStamp = this.$moment().format("x");
                if (this.currentTimeStamp > this.userInfo.freeReviewTime) {
                    this.vipOutTime = true;
                }
                if (this.userInfo.isVip) {
                    this.title = "续费会员";
                } else {
                    this.title = "开通会员";
                }
                // this.getDomainInfo();
                this.getVipPrice();
            }
        },
    },
    methods: {
        getDate(time) {
            return this.$moment(time).format("YYYY-MM-DD HH:mm:ss");
        },
        closePayModal() {
            this.payModalVisible = false;
            this.payData = {};
            this.tradeId = "";
        },
        openVip() {
            let params = {
                userCode: this.userInfo.userCode,
            };
            const _this = this;
            this.$http
                .post("/viporder/openVip", params)
                .then((res) => {
                    if (res.rescode == 200) {
                        _this.payData.payAmount = this.vipPriceInfo.amount;
                        _this.tradeId = res.msg;
                        _this.payModalVisible = true;
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDomainInfo() {
            // let domain = document.domain;
            let domain = "devjb.xbov.cn";
            let params = {
                domainName: domain,
            };
            this.$http
                .post("/domaincustom/queryList", params)
                .then((res) => {
                    let domainInfo = res.data.dataList[0];
                    this.domainInfo = domainInfo;
                    this.getVipPrice();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getVipPrice() {
            const _this = this;
            let params = {
                configKey: "membershipLevel",
            };
            _this.$http.post("/systemsetting/queryList", params).then((res) => {
                let list = res.data.dataList;
                let vipInfo = {};
                list.map((item) => {
                    let priceList = JSON.parse(item.configValue);
                    let obj = {
                        province: priceList[0].province,
                        priceList: priceList,
                    };
                    if (obj.province == _this.userInfo.province) {
                        vipInfo = obj;
                        _this.vipInfo = obj;
                    }
                });
                for (let i = 0; i < vipInfo.priceList.length; i++) {
                    if (vipInfo.priceList[i].initialization == "1") {
                        _this.vipPriceInfo = vipInfo.priceList[i];
                        break;
                    }
                }
                _this.getVipLevel(_this.userInfo.userCode);
            });
        },
        getVipLevel(userCode) {
            const _this = this;
            let params = {
                configKey: "membershipLevelUser",
                userCode: userCode,
            };
            this.$http.post("/usersetting/queryByUserCode", params).then((res) => {
                _this.spinning = false;
                if (res.rescode == 200 && res.data.configValue && res.data.configValue != "") {
                    let info = JSON.parse(res.data.configValue);
                    for (let i = 0; i < _this.vipInfo.priceList.length; i++) {
                        if (_this.vipInfo.priceList[i].level == info.level) {
                            _this.vipPriceInfo = _this.vipInfo.priceList[i];
                            break;
                        }
                    }
                }
            });
        },
        closeModal() {
            this.closeOpenVipModal(2);
            this.domainInfo = {};
            this.vipPriceInfo = {};
            this.title = "";
        },
    },
};
</script>
<style lang="less" scoped>
.price {
    margin-bottom: 8px;
}
.submit {
    margin-top: 8px;
}
</style>
