import Vue from 'vue';
import VueRouter from 'vue-router';
import { get, post, updateFile } from '../utils/axios/https';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Login',
		component: () => import(/* webpackChunkName: "Login" */ '../views/login/Login.vue'),
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "Register" */ '../views/login/Register.vue'),
	},
	{
		path: '/forgetPassword',
		name: 'ForgetPassword',
		component: () => import(/* webpackChunkName: "ForgetPassword" */ '../views/login/ForgetPassword.vue'),
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Home/Home.vue'),
	},
	{
		path: '/checkAdvertising',
		name: 'CheckAdvertising',
		component: () => import(/* webpackChunkName: "CheckAdvertising" */ '../views/CheckAdvertising/CheckAdvertising.vue'),
	},
	{
		path: '/checkContract',
		name: 'CheckContract',
		component: () => import(/* webpackChunkName: "CheckAdvertising" */ '../views/CheckContract/CheckContract.vue'),
	},
	{
		//财务管理
		path: '/financialManagement',
		name: 'FinancialManagement',
		component: () => import(/* webpackChunkName: "FinancialManagement" */ '../views/FinancialManagement/FinancialManagement.vue'),
	},
	{
		//检测订单
		path: '/checkOrder',
		name: 'CheckOrder',
		component: () => import(/* webpackChunkName: "CheckOrder" */ '../views/CheckOrder/CheckOrder.vue'),
	},
	{
		//合同订单
		path: '/contractOrder',
		name: 'ContractOrder',
		component: () => import(/* webpackChunkName: "CheckOrder" */ '../views/ContractOrder/ContractOrder.vue'),
	},
	{
		//安全检查
		path: '/checkSecurity',
		name: 'CheckSecurity',
		component: () => import(/* webpackChunkName: "checkSecurity" */ '../views/checkSecurity/checkSecurity.vue'),
	},
	{
		//安全检查订单
		path: '/securityOrder',
		name: 'SecurityOrder',
		component: () => import(/* webpackChunkName: "SecurityOrder" */ '../views/SecurityOrder/SecurityOrder.vue'),
	},

	{
		//检测订单详情
		path: '/orderDetail',
		name: 'OrderDetail',
		component: () => import(/* webpackChunkName: "OrderDetail" */ '../views/CheckOrder/OrderDetail.vue'),
	},
	{
		//安全检测订单详情
		path: '/safeDetail',
		name: 'SafeDetail',
		component: () => import(/* webpackChunkName: "OrderDetail" */ '../views/SecurityOrder/SafeDetail.vue'),
	},
	{
		//个人中心
		path: '/personCenter',
		name: 'PersonCenter',
		component: () => import(/* webpackChunkName: "PersonCenter" */ '../views/PersonCenter/PersonCenter.vue'),
	},
	{
		//开票
		path: '/makeOutAnInvoice',
		name: 'MakeOutAnInvoice',
		component: () => import(/* webpackChunkName: "MakeOutAnInvoice" */ '../views/MakeOutAnInvoice/MakeOutAnInvoice.vue'),
	},
	{
		//发票记录
		path: '/invoiceRecord',
		name: 'InvoiceRecord',
		component: () => import(/* webpackChunkName: "InvoiceRecord" */ '../views/InvoiceRecord/InvoiceRecord.vue'),
	},
	// 法律学习
	{
		path: '/lawStudy',
		name: 'LawStudy',
		component: () => import(/* webpackChunkName: "LawStudy" */ '../views/LearningCenter/LawStudy/LawStudy.vue'),
	},
	// 通知公告
	{
		path: '/notice',
		name: 'Notice',
		component: () => import(/* webpackChunkName: "LawStudy" */ '../views/Notice/Notice.vue'),
	},
	// 移动端
	{
		path: '/mobile',
		name: 'Mobile',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/Mobile/Mobile.vue'),
	},
	// 专家人才库
	{
		path: '/talentPool',
		name: 'TalentPool',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/TalentPool/TalentPool.vue'),
	},
	// 专家详情
	{
		path: '/details',
		name: 'Details',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/TalentPool/Details.vue'),
	},
	// 专家资料详情
	{
		path: '/information',
		name: 'Information',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/TalentPool/Information.vue'),
	},
	// 活动培训
	{
		path: '/industryTraining',
		name: 'IndustryTraining',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/IndustryTraining/IndustryTraining.vue'),
	},
	// 培训详情
	{
		path: '/industryTrainingDetail',
		name: 'IndustryTrainingDetail',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/IndustryTraining/IndustryTrainingDetail.vue'),
	},
	// 律师咨询
	{
		path: '/attorney',
		name: 'Attorney',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/Attorney/Attorney.vue'),
	},
	// 律师详情
	{
		path: '/lawyer',
		name: 'Lawyer',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/Attorney/Lawyer.vue'),
	},
	// 查看律师资料
	{
		path: '/iawyerInformation',
		name: 'IawyerInformation',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/Attorney/IawyerInformation.vue'),
	},
	// 订单中心
	{
		path: '/orderCenter',
		name: 'OrderCenter',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/OrderCenter/OrderCenter.vue'),
	},
	//投放监播
	{
		path: '/PutIn',
		name: 'PutIn',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/PutIn/PutIn.vue'),
	},
	//优惠券管理
	{
		path: '/discountAdmin',
		name: 'DiscountAdmin',
		component: () => import(/* webpackChunkName: "Mobile" */ '../views/discountAdmin/discountAdmin.vue'),
	},
	//媒介资源库
	{
		path: '/mediaResources',
		name: 'MediaResources',
		component: () => import(/* webpackChunkName: "PersonalCenter" */ '../views/MediaResources/MediaResources.vue'),
	},
	//开通会员页面
	{
		path: '/openVip',
		name: 'OpenVip',
		component: () => import(/* webpackChunkName: "OpenVip" */ '../views/OpenVip/OpenVip.vue'),
	},
	//开通会员合同页面
	{
		path: '/vipContractManager',
		name: 'VipContractManager',
		component: () => import(/* webpackChunkName: "OpenVip" */ '../views/VipContractManager/VipContractManager.vue'),
	},
];

const router = new VueRouter({
	routes,
});

// 路由守卫，除了登录页面，其他页面需校验token是否存在，若不存在则跳回登录页
router.beforeEach((to, from, next) => {
	if (
		to.name === 'Login' ||
		to.name === 'Register' ||
		to.name === 'ForgetPassword' ||
		to.name === 'Mobile' ||
		to.name === 'Details' ||
		to.name === 'Information' ||
		to.name === 'IndustryTrainingDetail' ||
		to.name === 'CheckAdvertising' ||
		to.name == 'Lawyer' ||
		to.name == 'IawyerInformation'
	) {
		if (to.name === 'CheckAdvertising') {
			let token = router.app.$options.store.getters.token;
			if (token) {
				next();
			} else if (router.history.pending.query.tokenKey) {
				let params = {
					sourceMaterialKey: router.history.pending.query.sourceMaterialKey,
					tokenKey: router.history.pending.query.tokenKey,
					userKey: router.history.pending.query.userKey,
				};
				post('/userbasic/selectValueByKey', params)
					.then(res => {
						if (res.data.tokenValue) {
							router.app.$options.store.dispatch('saveToken', res.data.tokenValue);
							router.app.$options.store.dispatch('saveUserInfo', res.data.userConsumerVo);
							next();
						} else {
							router
								.push({
									name: 'Login',
								})
								.then(r => {});
						}
					})
					.catch(err => {
						console.log(err);
					});
			} else {
				router
					.push({
						name: 'Login',
					})
					.then(r => {});
			}
		} else {
			next();
		}
	} else {
		let token = router.app.$options.store.getters.token;
		if (!token) {
			router
				.push({
					name: 'Login',
				})
				.then(r => {});
		} else {
			next();
		}
	}
});

export default router;
