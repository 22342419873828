/**
 * 移动环境判断
 */
function isMobile () {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

/**
 * 取区间的随机数
 * @param {*} start 开始值
 * @param {*} end 结束值
 * @returns 随机数
 */
function getRandomNumberByRange (start, end) {
    return Math.floor(Math.random() * (end - start) + start)
}

/**
 * 数组排序
 * @param {*} arr 源数组
 * @param {*} property 数组中的字段
 * @param {*} type 排序方式：1、升序；-1降序
 * @returns 
 */
function arrCompare (arr, property, type) {
    arr.sort(function (item1, item2) {
        //1为升序 2为降序
        if (type == 1) {
            return item1[property] - item2[property]
        } else if (type == -1) {
            return item2[property] - item1[property]
        }
    })
    return arr
}

/**
 * 
 * @param {*} url 网址
 * @returns true：真实网址
 */
function isUrl (url) {
    return /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(url)
}

/**
 * 中文正则
 * @returns true:中文,false:非中文
 */
function chineseTest (value) {
    let reg = /^[\u4e00-\u9fa5]+$/gi;
    return reg.test(value);
}

/**
 * 手机号码正则
 * @returns true:正确手机号码,false:非正确手机号码
 */
function phoneTest (value) {
    let flag = /^1[23456789]\d{9}$/.test(value)
    return flag;
}

/**
 * 只能为数字正则
 * @returns true:数字,false:非全数字
 */
function numTest (value) {
    let reg = /^[0-9]*$/g;
    return reg.test(value);
}

export { getRandomNumberByRange, arrCompare, isUrl, isMobile, chineseTest, phoneTest, numTest };