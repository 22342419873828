import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import usertalent from './usertalent'
import domainInfo from './domainInfo'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    usertalent,
    domainInfo
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
