<template>
	<div>
		<a-layout id="components-layout-demo-side">
			<a-layout-header
				class="header flex justify-between"
				v-if="currentRouteName != 'Login' && currentRouteName != 'Register' && currentRouteName != 'ForgetPassword' && currentRouteName != 'Mobile'"
			>
				<div class="logo flex align-center">
					<a-avatar :src="logoUrl"></a-avatar>
					{{ title }}
				</div>
				<a-menu theme="dark" mode="horizontal" class="headerMenu">
					<a-sub-menu style="float: right">
						<span slot="title">
							<a-icon type="user" />
							<!-- <img icon="user" class="img" :src="userInfo.avatarUrl" /> -->
							<span>{{ userInfo.name }}</span>
							<img v-if="userInfo.isVip == 1" class="vipLogo" src="../assets/img/vip.png" />
						</span>

						<a-menu-item v-if="userInfo.isVip == 0" @click="openVip">开通会员</a-menu-item>
						<a-menu-item v-else-if="userInfo.isVip == 1 || userInfo.isVip == 2" @click="openVip">续费会员</a-menu-item>
						<a-menu-item key="DiscountAdmin" @openChange="onOpenChange" @click="menuClick">优惠券管理</a-menu-item>
						<a-menu-item key="personCenter" @openChange="onOpenChange" @click="menuClick">个人中心</a-menu-item>
						<a-menu-item @click="loginOut">退出登录</a-menu-item>
					</a-sub-menu>
				</a-menu>
			</a-layout-header>
			<a-layout>
				<a-layout-sider v-if="currentRouteName != 'Login' && currentRouteName != 'Register' && currentRouteName != 'ForgetPassword' && currentRouteName != 'Mobile'">
					<a-menu :open-keys="openKeys" v-model="selectedKeys" mode="inline" @openChange="onOpenChange" @click="menuClick">
						<a-menu-item key="home">
							<a-icon type="home" />
							系统首页
						</a-menu-item>
						<a-menu-item key="checkAdvertising">
							<a-icon type="file-search" />
							广告检测
						</a-menu-item>
						<a-menu-item key="CheckOrder">
							<a-icon type="shopping-cart" />
							检测订单
						</a-menu-item>
						<a-menu-item key="checkContract">
							<a-icon type="file" />
							合同检测
						</a-menu-item>
						<a-menu-item key="contractOrder">
							<a-icon type="shopping-cart" />
							合同订单
						</a-menu-item>
						<a-menu-item key="CheckSecurity">
							<a-icon type="safety-certificate" />
							安全检测
						</a-menu-item>
						<a-menu-item key="SecurityOrder">
							<a-icon type="shopping-cart" />
							安全订单
						</a-menu-item>
						<a-menu-item key="FinancialManagement">
							<a-icon type="pay-circle" />
							财务管理
						</a-menu-item>
						<a-menu-item key="TalentPool">
							<a-icon type="user" />
							专家人才
						</a-menu-item>
						<a-menu-item key="IndustryTraining">
							<a-icon type="usergroup-add" />
							活动培训
						</a-menu-item>
						<a-menu-item key="Attorney">
							<a-icon type="message" />
							法律咨询
						</a-menu-item>
						<a-menu-item key="OrderCenter">
							<a-icon type="profile" />
							订单中心
						</a-menu-item>
						<a-menu-item key="PutIn">
							<a-icon type="snippets" />
							投放监播
						</a-menu-item>
						<a-menu-item key="MediaResources">
							<a-icon type="windows" />
							媒介资源
						</a-menu-item>
						<a-menu-item key="VipContractManager">
							<a-icon type="file" />
							会员合同
						</a-menu-item>
					</a-menu>
				</a-layout-sider>
				<a-layout>
					<a-layout-content class="layoutContent">
						<div class="layoutContent-box">
							<router-view id="view" />
						</div>
					</a-layout-content>
					<a-layout-footer v-if="currentRouteName != 'Login' && currentRouteName != 'Register' && currentRouteName != 'ForgetPassword' && currentRouteName != 'Mobile'" class="footer">
						<div>
							© Copyright 2015 - {{ currentYear }}
							{{
								domain == 'bc.zbov.cn'
									? '石家庄市北辰科技有限公司 版权所有 | 冀ICP备2021013237号-1'
									: '长沙鸿星智能科技有限公司 版权所有 | 湘ICP备2020023992号-2号 | 湘公网安备 43019002001389号'
							}}
						</div>
					</a-layout-footer>
				</a-layout>
			</a-layout>
		</a-layout>
		<OpenVipModal :openVipModalvisibile="openVipModalvisibile" :closeOpenVipModal="closeOpenVipModal" />
		<UpdateAddress :updataAddressVisible="updataAddressVisible" :closeUpdataAddressModal="closeUpdataAddressModal" />
	</div>
</template>
<script>
import OpenVipModal from './OpenVipModal.vue';
import UpdateAddress from './UpdateAddress.vue';
export default {
	components: { OpenVipModal, UpdateAddress },
	data() {
		return {
			title: '智广宝-鸿星智能广告服务平台',
			logoUrl: 'https://resources.xbov.cn/img/hongxingLogo.png',
			updataAddressVisible: false,
			rootSubmenuKeys: ['page'],
			openVipModalvisibile: false,
			openKeys: ['/'],
			selectedKeys: ['/'],
			currentRouteName: 'Login',
			src: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1692236545,1995714621&fm=11&gp=0.jpg',
			userInfo: {},
			currentYear: '',
			isVip: false,
			domain: '',
		};
	},
	watch: {
		$route(route) {
			this.currentRouteName = route.name;
			this.userInfo = this.$store.getters.userInfo;
			let domainInfo = this.$store.getters.domainInfo;
			this.domain = document.domain;
			if (domainInfo.domainName) {
				this.title = domainInfo.title;
				this.logoUrl = process.env.VUE_APP_RESOURCES_HOST + domainInfo.logoUrl;
			}
			if (this.userInfo.isVip == 1 && this.userInfo.freeReviewTime && this.$moment().format('x') <= this.userInfo.freeReviewTime) {
				this.isVip = true;
			}
			if (route.name == 'Home') {
				this.openKeys = ['page'];
			}
			if (route.name == 'Login') {
				this.selectedKeys = ['login'];
			} else {
				this.selectedKeys = [route.name];
				if (route.name == 'Home' || route.name == 'PersonCenter') {
					this.selectedKeys = ['home'];
				} else if (route.name == 'CheckAdvertising') {
					this.selectedKeys = ['checkAdvertising'];
				} else if (route.name == 'CheckContract') {
					this.selectedKeys = ['checkContract'];
				} else if (route.name == 'ContractOrder') {
					this.selectedKeys = ['contractOrder'];
				} else if (route.name == 'FinancialManagement' || route.name == 'MakeOutAnInvoice' || route.name == 'InvoiceRecord') {
					this.selectedKeys = ['FinancialManagement'];
				} else if (route.name == 'CheckOrder' || route.name == 'OrderDetail') {
					this.selectedKeys = ['CheckOrder'];
				} else if (route.name == 'OrderCenter') {
					this.selectedKeys = ['OrderCenter'];
				} else if (route.name == 'TalentPool' || route.name == 'Details') {
					this.selectedKeys = ['TalentPool'];
				} else if (route.name == 'IndustryTraining' || route.name == 'IndustryTrainingDetail') {
					this.selectedKeys = ['IndustryTraining'];
				} else if (route.name == 'Attorney' || route.name == 'Lawyer') {
					this.selectedKeys = ['Attorney'];
				} else if (route.name == 'PutIn') {
					this.selectedKeys = ['PutIn'];
				} else if (route.name == 'DiscountAdmin') {
					this.selectedKeys = ['DiscountAdmin'];
				} else if (route.name == 'DiscountAdmin') {
					this.selectedKeys = ['DiscountAdmin'];
				} else if (route.name == 'MediaResources') {
					this.selectedKeys = ['MediaResources'];
				}
			}
		},
	},
	created() {
		this.currentYear = this.$moment().format('YYYY');
		this.domain = document.domain;
	},
	methods: {
		closeUpdataAddressModal() {
			this.updataAddressVisible = false;
			this.userInfo = this.$store.getters.userInfo;
			location.reload();
		},
		closeOpenVipModal(type) {
			this.openVipModalvisibile = false;
			if (type == 1) {
				this.userInfo = this.$store.getters.userInfo;
				setTimeout(() => {
					location.reload();
				}, 500);
			}
		},
		openVip() {
			this.$router.push({
				name: 'OpenVip',
			});
		},
		userCenter() {
			this.$router.push({
				name: 'PersonCenter',
			});
		},
		loginOut() {
			this.$store.dispatch('saveUserInfo', {});
			this.$store.dispatch('saveToken', null);
			this.$store.dispatch('saveDomainInfo', {});
			this.isVip = false;
			this.$router.push({ name: 'Login' });
		},
		menuClick(item) {
			let path = item.key;
			if (path == 'home') {
				path = '/home';
				this.openKeys = [];
			}
			this.$router.push({ path: path });
		},
		onOpenChange(openKeys) {
			const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
			if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
				this.openKeys = openKeys;
			} else {
				this.openKeys = latestOpenKey ? [latestOpenKey] : [];
			}
		},
	},
};
</script>

<style lang="less" scoped>
.title {
	width: 100%;
	height: 50px;
	background-color: #151d49;
	color: #fff;
	font-size: 18px;
	padding: 0 20px;
	.left {
		display: inline-block;
		position: relative;
	}
	.floatR {
		position: relative;
		.close {
			cursor: pointer;
		}
		.userAvatar {
			margin-right: 8px;
			cursor: pointer;
		}
		.user {
			cursor: pointer;
			.ant-divider {
				margin: 0 16px;
			}
		}
	}
}
#components-layout-demo-side {
	height: 100vh;
	overflow: hidden;
	.header {
		padding: 0 24px;
		.headerMenu {
			line-height: 64px;
			// .img {
			// 	width: 40px;
			// 	height: 40px;
			// 	border-radius: 50px;
			// 	position: absolute;
			// 	top: 10px;
			// 	right: 85px;
			// }

			.vipLogo {
				width: 30px;
				height: 30px;
				position: absolute;
				top: 15px;
				right: -20px;
			}
		}
		.logo {
			color: #fff;
			font-size: 16px;
			.ant-avatar {
				margin-right: 8px;
			}
		}
	}
	.layoutContent {
		display: flex;
		background-size: cover;
		&-box {
			width: 100%;
		}
	}
	.footer {
		background-color: #fff;
		font-size: 14px;
		text-align: center;
		padding: 8px;
	}
}
</style>
