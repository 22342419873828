import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Moment from 'moment';
import 'lib-flexible';
import {
	Button,
	Menu,
	Layout,
	Row,
	Tabs,
	Input,
	Icon,
	Modal,
	Upload,
	message,
	Divider,
	Notification,
	Checkbox,
	Col,
	Select,
	Steps,
	DatePicker,
	Avatar,
	FormModel,
	Dropdown,
	Table,
	Descriptions,
	Popconfirm,
	Spin,
	Collapse,
	Tag,
	Form,
	ConfigProvider,
	Tooltip,
	Tree,
	Rate,
	Breadcrumb,
	Cascader,
	List,
	Radio,
	Pagination,
	InputNumber,
	Empty,
	Drawer,
	Alert,
	Result,
} from 'ant-design-vue';
import './assets/css/reset/reset.less';
import { get, post, updateFile } from './utils/axios/https';
import axios from 'axios';
import { getRandomNumberByRange, arrCompare, isUrl, isMobile, chineseTest, phoneTest, numTest } from './utils/common/commonUtil';
import { DictMapInput } from '@/components/DictMapInput.vue';
import VueClipboard from 'vue-clipboard2';

import VueAMap from 'vue-amap';

[
	Button,
	Menu,
	Layout,
	Row,
	Col,
	Tabs,
	Input,
	Icon,
	Modal,
	Upload,
	Divider,
	Checkbox,
	Select,
	Steps,
	DatePicker,
	Avatar,
	FormModel,
	Dropdown,
	Table,
	Descriptions,
	Popconfirm,
	Spin,
	Collapse,
	Tag,
	Form,
	ConfigProvider,
	Tooltip,
	Tree,
	Rate,
	Breadcrumb,
	Cascader,
	List,
	Radio,
	Pagination,
	InputNumber,
	Empty,
	Drawer,
	Alert,
	Result,
].forEach(element => {
	Vue.use(element);
});
Vue.use(Input.Group);
Vue.use(VueClipboard);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.prototype.$moment = Moment;
Vue.prototype.$message = message;
Vue.prototype.$modal = Modal;
Vue.prototype.$http = { get, post, updateFile };
Vue.prototype.$CommonUtil = { getRandomNumberByRange, arrCompare, isUrl, isMobile, chineseTest, phoneTest, numTest };
Vue.prototype.$Notification = Notification;
Vue.prototype.$success = Modal.success;
Vue.use(VueAMap);
Vue.component('DictMapInput', DictMapInput);

//初始化vue-amap
VueAMap.initAMapApiLoader({
	//在高德平台申请的key
	key: 'a68cfa9e236bde4322550467cef9a13b',
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geocoder'],
	v: '1.4.4',
});

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
