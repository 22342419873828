const user = {
    state: {
        user: {},
        token: null
    },
    mutations: {
        USER_INFO (state, info) {
            state.user = info
        },
        TOKEN (state, info) {
            state.token = info
        }
    },
    getters: {
        userInfo: state => {
            return state.user
        },
        token: state => {
            return state.token
        }
    },
    actions: {
        saveUserInfo ({ commit }, data) {
            commit('USER_INFO', data)
        },
        saveToken ({ commit }, data) {
            commit('TOKEN', data)
        }
    }
};
export default user